import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { ThemeProvider } from "@mui/material/styles";
import Themes from "./@components/designSystem/themes";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { hardhat, polygonMumbai, polygon } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import PaymentFlow from "./paymentFlow";
import { SnackbarProvider } from "notistack";

const getChainsForEnvironment = () => {
  if (window.env.CHAIN === "POLYGON") {
    console.log("POLYGON chain");
    return [polygon];
  } else if (window.env.CHAIN === "MUMBAI") {
    console.log("MUMBAI chain");
    return [polygonMumbai];
  } else {
    console.log("Return utilities chains");
    return [hardhat, polygonMumbai, polygon];
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const { chains, publicClient } = configureChains(
  getChainsForEnvironment(),
  [infuraProvider({ apiKey: "ed8f41cd961b407ca7310eec767be5a7" })],
  { stallTimeout: 10000, retryCount: 5, retryDelay: 2000 }
);

const { connectors } = getDefaultWallets({
  appName: "Qvrse Payments",
  projectId: "baab8c3625087d0dadd45a3c5b5c815e",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const Index = () => {
  return (
    <Route
      path="/brand/:brandId/collection/:collectionId"
      component={PaymentFlow}
    />
  );
};

ReactDOM.render(
  <BrowserRouter basename={""}>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: "#944DFF",
          accentColorForeground: "white",
          borderRadius: "none",
          fontStack: "system",
          overlayBlur: "small",
        })}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={Themes.Dark}>
            <SnackbarProvider
              preventDuplicate
              maxSnack={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              autoHideDuration={5000}
            >
              <Index />
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
