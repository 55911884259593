import React from "react";
import UI from "../../../../../@components/UI";
import { useFormData } from "../../../context";

const StripeButton = (props) => {
  const { data: formData } = useFormData();
  const {
    applyCustomStyles,
    title,
    orderId,
    DAO,
    collection,
    onSuccess,
    onCanceled,
    ...other
  } = props;
  const access_token = localStorage.getItem("access_token");
  const formRef = React.useRef();

  return (
    <>
      <form
        ref={formRef}
        action={`${window.env.GATEWAY}/payments/stripe/create-checkout-session/`}
        method="POST"
      >
        <input
          type="hidden"
          name="collection_id"
          value={collection?.id}
        ></input>
        <input type="hidden" name="order_id" value={formData.order_id}></input>
        <input type="hidden" name="user_id" value={formData.user_id}></input>
        <input
          type="hidden"
          name="nft_destination_address"
          value={formData.nft_destination_address}
        ></input>
        <input
          type="hidden"
          name="redirect_url"
          value={`${
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : window.env.URL
          }/brand/${DAO?.id}/collection/${collection?.id}/`}
        ></input>
        <input
          type="hidden"
          name="Authorization"
          value={`Bearer ${access_token}`}
        />
        <UI.Button
          disabled={collection.stripe === false}
          sx={{
            color: applyCustomStyles ? "#000" : "#fff",
            backgroundColor: applyCustomStyles
              ? `${DAO?.dao_style[0]?.data.background_color}`
              : "#080A19",
            "&:hover": {
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#944DFF",
              border: applyCustomStyles
                ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                : "0px solid #944DFF",
            },
          }}
          type={"primary"}
          title={title}
          {...other}
        />
      </form>
    </>
  );
};

export default StripeButton;
