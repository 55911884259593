import React from "react";
import { Box, Typography } from "@mui/material";
import CustomHooks from "../../../@components/hooks";
import useService from "../../useService";
import NFTVaultPasswordDialog from "./NFTVaultPasswordDialog";
import UI from "../../../@components/UI";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  requestPasswordData,
  freeMintPassword,
}) => {
  let query = CustomHooks.useQuery();
  const redirectUrl = query.get("redirectUrl");
  const service = useService();
  const [showNFTVaultPasswordDialog, setShowNFTVaultPasswordDialog] =
    React.useState(false);

  const { mutate: requestNFTVaultPassword } = CustomHooks.usePost(
    service.getNFTVaultPassword,
    {
      onSuccess: (response) => {
        const { password } = response.data;
        setShowNFTVaultPasswordDialog(password);
      },
    }
  );

  // When user buys free nft, password is already generated. So just show the dialog.
  React.useEffect(() => {
    if (freeMintPassword) {
      setShowNFTVaultPasswordDialog(freeMintPassword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeMintPassword]);

  // When user buys with fiat.
  React.useEffect(() => {
    if (requestPasswordData?.orderId) {
      requestNFTVaultPassword(requestPasswordData.orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPasswordData]);

  return (
    <>
      {showNFTVaultPasswordDialog && (
        <NFTVaultPasswordDialog
          DAO={DAO}
          collection={collection}
          password={showNFTVaultPasswordDialog}
          onClose={() => setShowNFTVaultPasswordDialog(false)}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          width: { md: "370px", mobile: "100%" },
        }}
      >
        <MobileHeader DAO={DAO} />
        <Box
          sx={{
            mt: { mobile: "30px", md: "0px" },
            width: { md: "370px", mobile: "unset" },
            px: { mobile: "20px", md: "0px" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "22px !important",
              color: "#080A19",
              textAlign: "center",
              lineHeight: "31px",
            }}
          >
            YOUR NFT IS ON ITS WAY TO YOUR DIGITAL WALLET
          </Typography>
          <Typography
            variant={"subtitle2"}
            sx={{
              mt: "18px",
              mb: "42px",
              fontFamily: "Open Sans",
              fontSize: "14px !important",
              color: "#080A19",
              textAlign: "center",
            }}
          >
            Great news! Your payment is currently being processed, and your NFT
            will be transferred to your digital wallet shortly. As soon as the
            transfer is complete, you'll be able to view it in your profile.
          </Typography>
        </Box>
        <UI.Button
          type={"primary"}
          title={redirectUrl ? "Go back" : "Close"}
          sx={{
            margin: "0 auto",
            width: "90%",
            color: applyCustomStyles ? "#000" : "#fff",
            backgroundColor: applyCustomStyles
              ? `${DAO?.dao_style[0]?.data.background_color}`
              : "#080A19",
            "&:hover": {
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#944DFF",
              border: applyCustomStyles
                ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                : "0px solid #944DFF",
            },
          }}
          onClick={() => {
            if (redirectUrl) {
              window.open(redirectUrl, "_self");
            } else {
              window.opener.postMessage(
                "PaymentSuccessful",
                (process.env.NODE_ENV === "development" &&
                  "http://localhost:3001/") ||
                  (window.env.CHAIN === "MUMBAI" &&
                    "https://mumbai.qvrse.io/") ||
                  (window.env.CHAIN === "POLYGON" && "https://dapp.qvrse.io/")
              );
            }
          }}
        />
      </Box>
    </>
  );
};

const MobileHeader = ({applyCustomStyles, DAO }) => {
  return (
    <Box
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#080A19",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        SUCCESS
      </Typography>
    </Box>
  );
};

export default Index;
