import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import truncateEthAddress from "truncate-eth-address";

const WrongWalletDialog = ({ userWalletAddress, onClose }) => {
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <Typography variant={"h2"}>Wrong Wallet</Typography>
      <Box sx={{ mt: "40px" }}>
        <Typography variant={"subtitle1"}>
          It seems you're attempting to connect with a different wallet than the
          one associated with your account. Please ensure that you connect with
          the correct wallet to proceed. Your account is connected with{" "}
          {truncateEthAddress(userWalletAddress || "")}.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <UI.Button
            type={"primary"}
            title={"OK"}
            onClick={() => onClose()}
            sx={{
              width: "150px",
              height: "50px",
              filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default WrongWalletDialog;
