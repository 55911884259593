import React from "react";
import Fiat from "./fiat";
import Crypto from "./crypto";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  previouseStep,
  nextStep,
}) => {
  return (
    <>
      {collection?.currency === "MATIC" && (
        <Crypto
          applyCustomStyles={applyCustomStyles}
          DAO={DAO}
          collection={collection}
          previouseStep={previouseStep}
          nextStep={nextStep}
        />
      )}
      {collection?.currency === "EUR" && (
        <Fiat
          applyCustomStyles={applyCustomStyles}
          DAO={DAO}
          collection={collection}
          nextStep={nextStep}
          previouseStep={previouseStep}
        />
      )}
    </>
  );
};

export default Index;
