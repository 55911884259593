import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const dao = async (daoId) => {
    const response = await gateway.get(`/dao/${daoId}/`);
    return response.data;
  };

  const registerUser = async (email) => {
    const response = await gateway.postForm(`/check_for_payment_user/`, {
      email,
    });
    return response.data;
  };

  const collection = async (daoId, id) => {
    const response = await gateway.get(`dao/${daoId}/collection/${id}/`);
    return response.data;
  };

  const getNextCollectionImage = async (daoId, id) => {
    const response = await gateway.post(
      `dao/${daoId}/collection/${id}/get_next_ticket_image/`
    );
    return response.data;
  };

  const buyTicket = (id, data) => {
    return gateway.post(
      `dao/${data.dao_id}/collection/${id}/buy_ticket/`,
      data
    );
  };

  const getNFTVaultPassword = (orderId) => {
    return gateway.post(`get_nft_vault_password/`, {
      order_id: orderId,
    });
  };

  const createOrder = async (data) => {
    const response = await gateway.post(
      `dao/${data.dao_id}/collection/${data.collection_id}/order/new/`,
      data.data
    );
    return response.data;
  };

  return {
    getNFTVaultPassword,
    buyTicket,
    createOrder,
    registerUser,
    dao,
    collection,
    getNextCollectionImage,
  };
};

export default useService;
