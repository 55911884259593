import React from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import UI from "../../../@components/UI";
import { Alert } from "@mui/lab";
import CustomHooks from "../../../@components/hooks";
import useService from "../../useService";
import { useFormData } from "../context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  nextStep,
  previouseStep,
}) => {
  const [requestInfoFields, setRequestInfoFields] = React.useState([]);
  const service = useService();
  const { setFormValues: setFormData } = useFormData();

  React.useEffect(() => {
    if (collection?.utilities.length === 0) return;

    const fields = collection?.utilities
      .flatMap((utility) => utility.request_info_fields)
      .filter((field) => field !== undefined);

    setRequestInfoFields(fields || []);
  }, [collection]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  const { mutate: newOrder } = CustomHooks.usePost(service.createOrder, {
    onSuccess: (response) => {
      const { id: orderId } = response;
      setFormData({ order_id: orderId });
      nextStep();
    },
  });

  const onSubmit = async (form) => {
    const data = {
      collection_id: collection.id,
      additional_info: { ...form },
      payment_type: "PAYPAL",
    };

    setFormData({ additional_info: { ...form } });

    await newOrder({
      dao_id: DAO.id,
      collection_id: collection.id,
      data: data,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { md: "370px", mobile: "100%" },
      }}
    >
      <MobileHeader
        applyCustomStyles={applyCustomStyles}
        DAO={DAO}
        previouseStep={previouseStep}
      />
      <Box
        sx={{
          mt: { mobile: "30px", md: "0px" },
          width: { md: "370px", mobile: "unset" },
          px: { mobile: "20px", md: "0px" },
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "22px !important",
            color: "#080A19",
            textAlign: "center",
          }}
        >
          Requested Information
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{
            mt: "18px",
            mb: "30px",
            fontFamily: "Open Sans",
            fontSize: "14px !important",
            color: "#080A19",
            textAlign: "center",
          }}
        >
          Please provide the following information so the brand can create
          personalized NFT experiences for you.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {requestInfoFields && requestInfoFields?.length > 0 && (
            <Box sx={{ mt: { md: "40px", mobile: "20px" } }}>
              {requestInfoFields?.map((data) => {
                return (
                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                    key={data?.id}
                  >
                    <Box sx={{ display: "flex", mb: "10px" }}>
                      <Typography
                        variant={"h4"}
                        sx={{ color: "#000", fontSize: "16px !important" }}
                      >
                        {data?.title}
                      </Typography>
                      <UI.Tooltip>{data?.tooltip}</UI.Tooltip>
                    </Box>
                    <Controller
                      name={data?.title}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <UI.Inputs.TextField
                          {...field}
                          backgroundColor={"#ffffff"}
                          placeholder={data?.placeholder}
                        />
                      )}
                    />
                    <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
                      {errors[data?.title]?.type === "required" && (
                        <Alert
                          sx={{
                            color: (theme) => theme.palette.colors.darkRed,
                          }}
                          variant="outlined"
                          severity="error"
                        >
                          {`${data?.title} is required`}
                        </Alert>
                      )}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          {requestInfoFields && requestInfoFields?.length === 0 && (
            <Box sx={{ my: { md: "40px", mobile: "20px" } }}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "12px !important",
                  color: "#080A19",
                  fontWeight: "bold",
                  lineHeight: "18px",
                  mb: "45px",
                  textAlign: "center",
                }}
              >
                ** No additional information is required at this time. You can
                continue with the payment process for your NFT purchase.
              </Typography>
            </Box>
          )}
          <UI.Button
            type={"primary"}
            sx={{
              color: applyCustomStyles ? "#000" : "#fff",
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#080A19",
              "&:hover": {
                backgroundColor: applyCustomStyles
                  ? `${DAO?.dao_style[0]?.data.background_color}`
                  : "#944DFF",
                border: applyCustomStyles
                  ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                  : "0px solid #944DFF",
              },
            }}
            title={"Continue"}
          />
        </form>
      </Box>
    </Box>
  );
};

const MobileHeader = ({ applyCustomStyles, DAO, previouseStep }) => {
  return (
    <Box
      onClick={previouseStep}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#080A19",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height:"20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        REQUEST INFO
      </Typography>
    </Box>
  );
};

export default Index;
