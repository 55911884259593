import React from "react";
import jsPDF from "jspdf";
import UI from "../../../@components/UI";
import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { openSansFont, openSansItalic } from "./customFont";

function DownloadPDFButton({ DAO, collection, password }) {
  const [isLoading, setIsLoading] = React.useState(false);

  function removeEmojis(str) {
    const emojiRange =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE0F}]/gu;

    return str.replace(emojiRange, "");
  }

  async function getRoundedImageDataURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // handle CORS
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw a circle
        ctx.beginPath();
        ctx.arc(
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2,
          0,
          Math.PI * 2,
          false
        );
        ctx.closePath();
        ctx.clip();

        // Draw the image inside the circle
        ctx.drawImage(img, 0, 0);

        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = reject;
      img.src = url;
    });
  }

  function fetchImageAndConvertToBase64(imgURL) {
    return fetch(imgURL)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }

  const generatePDF = async () => {
    return new Promise(async (resolve) => {
      const doc = new jsPDF("p", "mm", "a4", true);
      // add the font to jsPDF
      doc.addFileToVFS("MyFont.ttf", openSansFont);
      doc.addFileToVFS("MyFontItalic.ttf", openSansItalic);

      doc.addFont("MyFont.ttf", "MyFont", "normal");
      doc.addFont("MyFontItalic.ttf", "MyFontItalic", "italic");

      doc.setFont("MyFont");
      // Set background color
      doc.setFillColor(8, 10, 25); // Equivalent of #f5f5f5
      doc.rect(0, 0, 210, 297, "F"); // Fill the entire A4 page

      // Assuming the image is already converted to Base64
      const backgroundImgBase64 = await fetchImageAndConvertToBase64(
        "https://hazellabs.infura-ipfs.io/ipfs/QmeMiVbJvQ7ABazpkKLcaQMDL5b16HKbq4TJT19a4USCKK"
      );

      // A4 dimensions are 210mm x 297mm
      doc.addImage(
        backgroundImgBase64,
        "JPEG",
        0,
        0,
        210,
        297,
        undefined,
        "FAST"
      );

      const url = DAO?.avatar_url;
      const dataURL = await getRoundedImageDataURL(url);

      doc.addImage(dataURL, "PNG", 70, 10, 70, 70, undefined, "FAST");
      // Add text

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(16);

      const paddingLeft = 10; // 20mm from the left
      doc.text(
        "YOU'VE SECURED YOUR NFT - DON'T MISS THIS ESSENTIAL STEP!",
        paddingLeft,
        110,
        { align: "left" }
      );

      doc.setFontSize(14);

      doc.text(
        "Congratulations on acquiring your NFT! It's now safely stored in our vault." +
          "\n" +
          "If you wish to transfer the NFT to your blockchain wallet in the future,\n" +
          "you'll need the One-Time Password (OTP) provided below.\n" +
          "\n",
        paddingLeft,
        130,
        { align: "left" }
      );
      //doc.setFont("MyFont", "bold");
      // Draw rectangle around password
      const rectWidth = 190; // width of the rectangle
      const rectHeight = 32; // height of the rectangle
      const rectX = paddingLeft; // X position
      const rectY = 155; // Y position slightly above the password text to center it vertically

      doc.setDrawColor(148, 77, 255); // Light gray color for the border
      doc.rect(rectX, rectY, rectWidth, rectHeight, "D"); // Draw and fill the rectangle

      doc.text(password, paddingLeft + 10, 165, {
        maxWidth: 170,
        align: "left",
      }); // Then draw the password text

      doc.text(
        "Please ensure you store this password in a safe place.\n" +
          "We've also sent the OTP to your registered email address for your convenience.\n",
        paddingLeft,
        200,
        { align: "left" }
      );

      doc.setFontSize(12);

      doc.setFont("MyFontItalic", "italic");

      doc.text(
        `Collection: ${removeEmojis(collection.name)}\n` +
          `NFT ID: ${collection.nft_amount_minted - 1}\n`,
        paddingLeft,
        220,
        { align: "left" }
      );

      doc.save(
        `${removeEmojis(collection.name)}-${
          collection.nft_amount_minted
        }-vault-password.pdf`,
        { returnPromise: true }
      );
      resolve("resolved");
    });
  };

  return (
    <UI.Button
      disabled={isLoading}
      startIcon={
        !isLoading ? (
          <PictureAsPdfIcon sx={{ mr: "8px" }} />
        ) : (
          <CircularProgress size={20} color={"primary"} />
        )
      }
      type={"primary"}
      title={"Save to device"}
      onClick={async () => {
        setIsLoading(true);
        generatePDF().then((res) => {
          setIsLoading(false);
        });
      }}
      sx={{
        width: "220px",
        height: "50px",
        filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
      }}
    />
  );
}

export default DownloadPDFButton;
