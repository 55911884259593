import React from "react";
import CustomHooks from "../@components/hooks";
import useService from "./useService";
import { Box, Container, Paper, Typography } from "@mui/material";
import { Skeleton } from "@mui/lab";
import UI from "../@components/UI";
import Form from "./form";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";

const Index = () => {
  let query = CustomHooks.useQuery();
  const service = useService();
  const { brandId, collectionId } = useParams();
  const sourceOpener = query.get("source");
  const [activeStep, setActiveStep] = React.useState(0);
  const [showCustomStyles, setShowCustomStyles] = React.useState(true);

  React.useEffect(() => {
    if (sourceOpener === "qvrse") {
      setShowCustomStyles(false);
    }
  }, [sourceOpener]);

  const { data: DAO, isLoading: loadingBrand } = CustomHooks.useFetch(
    "dao",
    () => service.dao(brandId)
  );
  const { data: collection, isLoading: loadingCollection } =
    CustomHooks.useFetch("collection", () =>
      service.collection(brandId, collectionId)
    );

  return (
    <Box sx={{ BackgroundColor: "rgba(255,255,255,0.47)" }}>
      <UI.Busy.FullscreenIndicator show={loadingBrand || loadingCollection}>
        <Typography variant={"subtitle2"} sx={{ color: "#080a19", textAlign: "center", fontFamily:"Open Sans" }}>
          Preparing your payment ...
        </Typography>
        <Typography variant={"subtitle2"} sx={{ color: "#080a19", textAlign: "center", fontFamily:"Open Sans" }}>
          It will take a few seconds.
        </Typography>
      </UI.Busy.FullscreenIndicator>

      <Header DAO={DAO} applyCustomStyles={showCustomStyles} />

      {(!loadingBrand || !loadingCollection) && (
        <Container maxWidth={"lg"} sx={{ p: { mobile: "0px" } }}>
          <Box
            sx={{
              px: { md: "117px", mobile: "0px" },
              display: { md: "flex", mobile: "none" },
              mt: "50px",
            }}
          >
            <Box>
              <Box sx={{ mb: "60px" }}>
                <BackButton
                  DAO={DAO}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              </Box>

              <Typography
                variant={"h2"}
                sx={{ fontSize: "26px !important", color: "#000000" }}
              >
                {collection?.name}
              </Typography>
              {collection?.currency && (
                <Box
                  sx={{
                    mt: "20px",
                    width: "25px",
                    height: "25px",
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <UI.PaymentIcon currency={collection?.currency} />
                  <Typography
                    variant={"h2"}
                    sx={{
                      ml: "11px",
                      fontSize: "18px !important",
                      color: "#000000",
                    }}
                  >
                    {collection?.total_price_with_tax}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              px: { md: "117px", mobile: "0px" },
              display: { md: "flex", mobile: "block" },
            }}
          >
            <Box
              sx={{
                flex: "1",
                display: { md: "flex", mobile: "none" },
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ mt: "74px", display: "flex", justifyContent: "center" }}
              >
                <UI.ImageWithSkeleton
                  src={collection?.nft_image_url}
                  sx={{ width: "280px", height: "auto" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { md: "flex", mobile: "none" },
                width: "1.5px",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
            />
            <Box sx={{ flex: "1" }}>
              <Form
                applyCustomStyles={showCustomStyles}
                DAO={DAO}
                collection={collection}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </Box>
          </Box>
        </Container>
      )}
      <Footer />
    </Box>
  );
};

const Header = ({ applyCustomStyles, DAO }) => {
  return (
    <Box
      sx={{
        position: { mobile: "absolute", md: "relative" },
        width: "100%",
        bottom: 0,
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.background_color
          : "#ffffff",
        height: "88px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          mt: "0px",
          mb: "20px",
          overflow: "hidden",
          transition: "all 0.5s ease-in-out",
          border: "0px solid transparent",
          "&:hover": {
            border: "0px solid #080a19",
            transform: "scale(1.1)",
          },
        }}
      >
        {DAO?.avatar_url ? (
          <Box
            component={"img"}
            sx={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              textAlign: "center",
              objectFit: "cover",
            }}
            src={DAO?.avatar_url}
            alt={"dao-logo"}
          />
        ) : (
          <Skeleton variant={"circle"} width={80} height={80} />
        )}
      </Paper>
    </Box>
  );
};

const BackButton = ({ activeStep, setActiveStep }) => {
  return (
    <Box
      onClick={() => setActiveStep(activeStep - 1)}
      sx={{
        cursor: "pointer",
        height: "58px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {activeStep !== 0 && (
        <ArrowBackIosIcon sx={{ color: "rgba(128, 128, 128, 1)" }} />
      )}
      <Typography
        variant={"subtitle1"}
        sx={{
          ml: "20px",
          color: "#000",
          fontSize: "14px !important",
          fontWeight: "bold",
        }}
      >
        {activeStep === 1 && "LOGIN"}
        {activeStep === 2 && "REQUESTED INFORMATION"}
        {activeStep === 3 && "CHOOSE DESTINATION"}
      </Typography>
    </Box>
  );
};

const Footer = () => {
  return <Box></Box>;
};

export default Index;
