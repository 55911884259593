import React from "react";
import { Box, Typography } from "@mui/material";
import UI from "../../../@components/UI";
import { useForm, Controller } from "react-hook-form";
import CustomHooks from "../../../@components/hooks";
import { Alert } from "@mui/lab";
import { useFormData } from "../context";
import useService from "../../useService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Index = ({ applyCustomStyles, DAO, nextStep }) => {
  let query = CustomHooks.useQuery();
  const service = useService();
  const { data: formData, setFormValues: setFormData } = useFormData();
  const loggedInUserEmail = query.get("email");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: formData?.user_email || "",
    },
    mode: "onSubmit",
  });

  const { mutate: registerUser } = CustomHooks.usePost(service.registerUser, {
    onSuccess: (response) => {
      const { access, user } = response;
      const { id: userId, address: walletAddress } = user;
      setFormData({ user_id: userId, wallet_address: walletAddress });
      localStorage.setItem("access_token", access);
      nextStep();
    },
  });

  React.useEffect(() => {
    const fetchUser = async () => {
      if (loggedInUserEmail && registerUser && DAO) {
        await logInSignedInUser(loggedInUserEmail);
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerUser, loggedInUserEmail, DAO]); // Add emailRecieved as a dependency so the listener is removed once email is received.

  const logInSignedInUser = async (email) => {
    await registerUser(email);
    setFormData({ user_email: email });
  };

  const onSubmit = async (form) => {
    const { email } = form;
    await registerUser(email);
    setFormData({ user_email: email });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        width: { md: "370px", mobile: "100%" },
      }}
    >
      <MobileHeader DAO={DAO} />
      <Box
        sx={{
          mt: { mobile: "30px", md: "0px" },
          width: { md: "370px", mobile: "unset" },
          px: { mobile: "20px", md: "0px" },
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "22px !important",
            color: "#080A19",
            textAlign: "center",
          }}
        >
          enter to proceed
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            mt: "18px",
            mb: "30px",
            fontFamily: "Open Sans",
            fontSize: "14px !important",
            color: "#080A19",
            textAlign: "center",
          }}
        >
          We need your email address to send updates about your purchase.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message:
                    "Please enter a valid email address. (e.g. user@example.com)",
                },
              }}
              render={({ field }) => (
                <UI.Inputs.TextField
                  {...field}
                  fullWidth
                  backgroundColor={"#ffffff"}
                  placeholder={"Email"}
                />
              )}
            />
            <Typography variant={"subtitle1"} sx={{ mt: "15px" }}>
              {errors.email && (
                <Alert
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                  }}
                  variant="outlined"
                  severity="error"
                >
                  {errors.email?.type === "pattern" && errors.email.message}
                  {errors.email?.type === "required" && "Email is required"}
                </Alert>
              )}
            </Typography>
          </Box>

          <UI.Button
            type={"primary"}
            sx={{
              mt:"35px",
              color: applyCustomStyles ? "#000" : "#fff",
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#080A19",
              "&:hover": {
                backgroundColor: applyCustomStyles
                  ? `${DAO?.dao_style[0]?.data.background_color}`
                  : "#944DFF",
                border: applyCustomStyles
                  ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                  : "0px solid #944DFF",
              },
            }}
            title={"Continue"}
          />
          <Typography
            variant={"subtitle2"}
            sx={{
              fontSize: "11px !important",
              color: "#080A19",
              fontWeight: "bold",
              lineHeight: "18px",
              mb: "45px",
              mt: "13px",
              textAlign: "center",
              fontFamily: "Open Sans",
            }}
          >
            By continuing, you confirm that you have read the Privacy Policy.
          </Typography>
        </form>
      </Box>
    </Box>
  );
};

const MobileHeader = ({ applyCustomStyles, DAO }) => {
  const goBack = () => {
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      // Handle the case where there is no history
      window.location.href = "/default";
    }
  };
  return (
    <Box
      onClick={goBack}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#000",
        height: "58px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "14px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        LOGIN
      </Typography>
    </Box>
  );
};

export default Index;
