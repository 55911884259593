import React from "react";
import { Box, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import paypalLogo from "../../../../static/paypal.png";
import visaLogo from "../../../../static/visa-logo.png";
import dinersLogo from "../../../../static/diners-logo.png";
import amexLogo from "../../../../static/amex-logo.png";
import mastercardLogo from "../../../../static/mastercard-logo.png";
import maestroLogo from "../../../../static/maestro-logo.png";
import Paypal from "./paypal";
import Stripe from "./stripe";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  nextStep,
  previouseStep,
}) => {
  const { control, watch } = useForm({
    defaultValues: {
      selected_payment_method: DAO?.fiat_payment_options?.paypal
        ? "paypal"
        : "card",
    },
    mode: "onSubmit",
  });

  const selected_payment_method_value = watch("selected_payment_method");

  return (
    <PayPalScriptProvider
      options={{
        currency: collection?.currency || "EUR",
        "client-id":
          collection?.paypal_client_id ||
          "AR5-T_x1yhaiW1MhxuBvaJ79ft6ICI68GpNeEVc-M-nI2tRgTrPPNAEoz6Xy410fdbmJHIL-GOdRnW8x",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          width: { md: "370px", mobile: "100%" },
        }}
      >
        <MobileHeader DAO={DAO} previouseStep={previouseStep} />
        <Box
          sx={{
            mt: { mobile: "30px", md: "0px" },
            width: { md: "370px", mobile: "unset" },
            px: { mobile: "20px", md: "0px" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "22px !important",
              color: "#080A19",
              textAlign: "center",
              mb: "26px",
            }}
          >
            Choose Payment method
          </Typography>
          <form>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name={"selected_payment_method"}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Box
                      sx={{
                        display: DAO?.fiat_payment_options?.paypal
                          ? "flex"
                          : "none",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: "10px",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        mx: "0px",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          mx: "0px",
                          height: "50px",
                        }}
                        value="paypal"
                        control={
                          <Radio
                            sx={{
                              color: "rgba(0, 0, 0, 0.5)", // unselected color
                              "&.Mui-checked": {
                                color: "#000", // selected color
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant={"subtitle1"}
                            sx={{
                              fontSize: "14px !important",
                              color: "rgba(0, 0, 0, 0.5);",
                            }}
                          >
                            Paypal
                          </Typography>
                        }
                      />
                      <Box
                        component={"img"}
                        src={paypalLogo}
                        sx={{ mr: "17px", width: "32px", height: "22px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: DAO?.fiat_payment_options?.stripe
                          ? "flex"
                          : "none",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: "10px",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        mx: "0px",
                        height: "50px",
                      }}
                    >
                      <FormControlLabel
                        value="card"
                        sx={{ mx: "0px" }}
                        control={
                          <Radio
                            disabled={collection?.stripe === false}
                            sx={{
                              color: "rgba(0, 0, 0, 0.5)", // unselected color
                              "&.Mui-checked": {
                                color: "#000", // selected color
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant={"subtitle1"}
                            sx={{
                              fontSize: "14px !important",
                              color: "rgba(0, 0, 0, 0.5);",
                            }}
                          >
                            Card
                          </Typography>
                        }
                      />
                      <Box sx={{ mr: "14.5px" }}>
                        <Box
                          component={"img"}
                          src={visaLogo}
                          sx={{ mx: "2.5px", width: "32px", height: "22px" }}
                        />
                        <Box
                          component={"img"}
                          src={dinersLogo}
                          sx={{ mx: "2.5px", width: "32px", height: "22px" }}
                        />
                        <Box
                          component={"img"}
                          src={amexLogo}
                          sx={{ mx: "2.5px", width: "32px", height: "22px" }}
                        />
                        <Box
                          component={"img"}
                          src={mastercardLogo}
                          sx={{ mx: "2.5px", width: "32px", height: "22px" }}
                        />
                        <Box
                          component={"img"}
                          src={maestroLogo}
                          sx={{ mx: "2.5px", width: "32px", height: "22px" }}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                )}
              />
            </FormControl>
            <Typography
              variant={"subtitle1"}
              sx={{
                fontSize: "12px !important",
                color: "#080A19",
                fontWeight: "bold",
                lineHeight: "18px",
                mb: "35px",
                textAlign: "center",
                mt: "20px",
              }}
            >
              {`** You will be redirected to the ${
                selected_payment_method_value === "paypal" ? "PayPal" : "Stripe"
              } website, where you can finalise payment.`}
            </Typography>
            {selected_payment_method_value === "paypal" && (
              <Paypal.Button
                DAO={DAO}
                collection={collection}
                onSuccess={() => {
                  nextStep();
                }}
                onRequestPassword={(data) => {
                  if (data?.password) {
                    // history.push({
                    //     //free mint
                    //     pathname: `/brand/${DAO?.id}/collection/${id}/payment/success`,
                    //     state: { scrollToTop: true, password: data.password },
                    // });
                  } else {
                    nextStep({ requestPassword: true, orderId: data });
                  }
                }}
                onError={() => {}}
              />
            )}
            {selected_payment_method_value === "card" && (
              <Stripe.Button
                applyCustomStyles={applyCustomStyles}
                DAO={DAO}
                title={"Pay with Credit Card"}
                collection={collection}
                onSuccess={() => {}}
                onRequestPassword={() => {}}
                onError={() => {}}
              />
            )}
          </form>
        </Box>
      </Box>
    </PayPalScriptProvider>
  );
};

const MobileHeader = ({ applyCustomStyles, DAO, previouseStep }) => {
  return (
    <Box
      onClick={previouseStep}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#080A19",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height: "20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        PAYMENT
      </Typography>
    </Box>
  );
};

export default Index;
