import React from "react";
import {Box, Typography} from "@mui/material";
import UI from "../../../../@components/UI";
import Button from "./Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useAccount, useDisconnect} from "wagmi";
import {useConnectModal} from "@rainbow-me/rainbowkit";
import {useFormData} from "../../context";
import WrongWalletDialog from "./WrongWalletDialog";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FreeMint from "../fiat/freeMint";

const Index = ({
                   applyCustomStyles,
                   DAO,
                   collection,
                   nextStep,
                   previouseStep,
               }) => {
    const {openConnectModal} = useConnectModal();
    const {disconnect} = useDisconnect();
    const {isConnected, address} = useAccount();
    const {data: formData} = useFormData();
    const [showWrongWalletDialog, setShowWrongWalletDialog] =
        React.useState(false);

    React.useEffect(() => {

        if (
            isConnected &&
            formData?.wallet_address !== null &&
            formData?.wallet_address !== ""
        ) {
            if (address !== formData?.wallet_address) {
                setShowWrongWalletDialog(true);
                disconnect();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, formData, address]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: {md: "370px", mobile: "100%"},
            }}
        >
            {showWrongWalletDialog && (
                <WrongWalletDialog
                    userWalletAddress={formData?.wallet_address}
                    onClose={() => setShowWrongWalletDialog(false)}
                />
            )}
            <MobileHeader
                applyCustomStyles={applyCustomStyles}
                DAO={DAO}
                previouseStep={previouseStep}
            />
            <Box
                sx={{
                    mt: { mobile: "30px", md: "0px" },
                    width: { md: "370px", mobile: "unset" },
                    px: { mobile: "20px", md: "0px" },
                  }}
            >
                <Typography
                    variant={"h2"}
                    sx={{
                        fontSize: "22px !important",
                        color: "#080A19",
                        textAlign: "center",
                      }}
                >
                    {isConnected ? "Confirm Payment" : "Connect your wallet"}
                </Typography>
                <Typography
                    variant={"subtitle1"}
                    sx={{
                        mt: "18px",
                        mb: "30px",
                        fontFamily: "Open Sans",
                        fontSize: "14px !important",
                        color: "#080A19",
                        textAlign: "center",
                      }}
                >
                    Please connect your wallet to buy. If you don't have a wallet we
                    prepared tutorials here.
                </Typography>
                {collection?.total_price_with_tax === "0.00" &&
                    formData?.wallet_address !== "" &&
                    isConnected && (
                        <Button
                            title={"Get Free NFT"}
                            collection={collection}
                            collectionId={collection?.id}
                            DAO={DAO}
                            onSuccess={() => {
                                nextStep();
                            }}
                            startIcon={<AccountBalanceWalletIcon/>}
                        />
                    )}
                {collection?.total_price_with_tax === "0.00" &&
                    formData?.wallet_address === "" && (
                        <Box
                            sx={{display: "flex", mt: "20px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <FreeMint.Button
                                DAO={DAO}
                                orderId={formData?.order_id}
                                isFreeMintNoWallet={true}
                                title={"Get Free NFT"}
                                collection={collection}
                                onRequestPassword={(data) => nextStep(data)}
                            />
                        </Box>
                    )}

                {isConnected && collection?.total_price_with_tax > "0.00" && (
                    <Button
                        applyCustomStyles={applyCustomStyles}
                        title={"BUY"}
                        onSuccess={() => {
                            nextStep();
                        }}
                        DAO={DAO}
                        collection={collection}
                        collectionId={collection?.id}
                    />
                )}
                {!isConnected && formData?.wallet_address && (
                    <UI.Button
                        type={"primary"}
                        sx={{
                            color: applyCustomStyles ? "#000" : "#fff",
                            backgroundColor: applyCustomStyles
                                ? `${DAO?.dao_style[0]?.data.background_color}`
                                : "#080A19",
                            "&:hover": {
                                backgroundColor: applyCustomStyles
                                    ? `${DAO?.dao_style[0]?.data.background_color}`
                                    : "#944DFF",
                                border: applyCustomStyles
                                    ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                                    : "0px solid #944DFF",
                            },
                        }}
                        title={"CONNECT WALLET"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openConnectModal();
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

const MobileHeader = ({applyCustomStyles, DAO, previouseStep}) => {
    return (
        <Box
            onClick={previouseStep}
            sx={{
                backgroundColor: applyCustomStyles
                  ? DAO?.dao_style[0]?.data?.card_background_color
                  : "#080A19",
                height: "50px",
                display: { mobile: "flex", md: "none" },
                alignItems: "center",
                position: "relative",
            }}
        >
            <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height:"20px" }} />
            <Typography
                variant={"subtitle1"}
                sx={{
                color: "#fff",
                fontSize: "12px !important",
                position: "absolute",
                left: "50%",
                fontWeight: "bold",
                transform: "translateX(-50%)",
                }}
            >
                PAYMENT
            </Typography>
        </Box>
    );
};

export default Index;
