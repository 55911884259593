import React from "react";
import Register from "./register";
import RequestInfo from "./requestInfo";
import Destination from "./destination";
import Payment from "./payment";
import Success from "./success";
import FormProvider from "./context";
import { Box } from "@mui/material";
import CustomHooks from "../../@components/hooks";

const Index = ({
  DAO,
  applyCustomStyles,
  collection,
  activeStep,
  setActiveStep,
}) => {
  const [requestPasswordData, setRequestPasswordData] = React.useState(null);
  const [freeMintPassword, setFreeMintPassword] = React.useState(null);
  let query = CustomHooks.useQuery();

  React.useEffect(() => {
    const success = query.get("success");
    const request_password = query.get("call_password");
    const orderId = query.get("order_id");
    //const canceled = query.get("canceled");

    if (success && request_password?.toLowerCase() === "false") {
      setActiveStep(4);
    }

    if (request_password?.toLowerCase() === "true" && orderId) {
      setRequestPasswordData({ orderId: orderId });
      setActiveStep(4);
    }
    // eslint-disable-next-line
  }, [query]);

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <Register
            applyCustomStyles={applyCustomStyles}
            DAO={DAO}
            collection={collection}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 1:
        return (
          <RequestInfo
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 2:
        return (
          <Destination
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 3:
        return (
          <Payment
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={(data) => {
              if (data?.requestPassword === true && data.orderId) {
                setRequestPasswordData({ orderId: data.orderId });
              } else if (data?.password) {
                setFreeMintPassword(data?.password);
              }
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 4:
        return (
          <Success
            applyCustomStyles={applyCustomStyles}
            freeMintPassword={freeMintPassword}
            requestPasswordData={requestPasswordData}
            collection={collection}
            DAO={DAO}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <FormProvider>{renderStep(activeStep)}</FormProvider>
    </Box>
  );
};

export default Index;
