import React from "react";
import { Box, Typography } from "@mui/material";
import UI from "../../../@components/UI";
import { useForm, Controller } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useAccount } from "wagmi";
import truncateEthAddress from "truncate-eth-address";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { Alert } from "@mui/lab";
import { useFormData } from "../context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Index = ({ applyCustomStyles, DAO, nextStep, previouseStep }) => {
  const { data: formData, setFormValues: setFormData } = useFormData();
  const { isConnected, address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [hasWalletAddress, setHasWalletAddress] = React.useState(false);
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      selected_nft_destination:
        formData?.nft_destination_address === DAO?.nft_vault_contract.address
          ? "vault"
          : "wallet",
    },
    mode: "onSubmit",
  });

  React.useEffect(() => {
    if (formData?.wallet_address) {
      setHasWalletAddress(true);
      setValue("selected_nft_destination", "wallet");
      setValue("nft_destination_address", formData?.wallet_address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const selected_nft_destination_value = watch("selected_nft_destination");

  const onSubmit = (form) => {
    const { selected_nft_destination } = form;

    if (selected_nft_destination === "vault") {
      setFormData({ nft_destination_address: DAO?.nft_vault_contract.address });
    } else if (
      selected_nft_destination === "wallet" &&
      !hasWalletAddress &&
      isConnected
    ) {
      setFormData({ nft_destination_address: address });
    } else if (selected_nft_destination === "wallet" && hasWalletAddress) {
      setFormData({ nft_destination_address: formData?.wallet_address });
    }
    nextStep();
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        width: { md: "370px", mobile: "100%" },
      }}
    >
      <MobileHeader
        DAO={DAO}
        previouseStep={previouseStep}
        applyCustomStyles={applyCustomStyles}
      />
      <Box
        sx={{
          mt: { mobile: "30px", md: "0px" },
          width: { md: "370px", mobile: "unset" },
          px: { mobile: "20px", md: "0px" },
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "22px !important",
            color: "#080A19",
            textAlign: "center",
          }}
        >
          Choose Destination
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{
            mt: "15px",
            mb: "26px",
            fontFamily: "Open Sans",
            fontSize: "14px !important",
            lineHeight: "22px",
            color: "#080A19",
            textAlign: "center",
          }}
        >
          Choose where you want to send your NFT, either to your wallet or to
          our vault.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name={"selected_nft_destination"}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    sx={{ border: "1px solid rgba(0, 0, 0, 0.3)", mx: "0px", height: "50px" }}
                    value="vault"
                    control={
                      <Radio
                        disabled={formData.wallet_address}
                        sx={{
                          color: "rgba(0, 0, 0, 0.5)", // unselected color
                          "&.Mui-checked": {
                            color: "#000", // selected color
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={"subtitle1"}
                        sx={{
                          fontSize: "14px !important",
                          color: "rgba(0, 0, 0, 0.5);",
                        }}
                      >
                        Vault
                      </Typography>
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: "10px",
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      mx: "0px",
                    }}
                  >
                    <FormControlLabel
                      value="wallet"
                      sx={{ mx: "0px", height: "50px" }}
                      control={
                        <Radio
                          disabled={formData.wallet_address}
                          sx={{
                            color: "rgba(0, 0, 0, 0.5)", // unselected color
                            "&.Mui-checked": {
                              color: "#000", // selected color
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={"subtitle1"}
                          sx={{
                            fontSize: "14px !important",
                            color: "rgba(0, 0, 0, 0.5);",
                          }}
                        >
                          Wallet
                        </Typography>
                      }
                    />
                    {!isConnected &&
                      !hasWalletAddress &&
                      selected_nft_destination_value === "wallet" && (
                        <UI.Button
                          sx={{
                            width: "120px",
                            height: "38px",
                            mr: "20px",
                            backgroundColor:
                              DAO?.dao_style[0]?.data?.card_background_color,
                          }}
                          type={"primary"}
                          title={"Connect"}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openConnectModal();
                          }}
                        />
                      )}
                  </Box>
                </RadioGroup>
              )}
            />
          </FormControl>
          {selected_nft_destination_value === "wallet" && hasWalletAddress && (
            <Alert
              variant="outlined"
              severity="info"
              sx={{ color: "#4DB8FF", my: "8px" }}
            >
              <Typography
                variant={"subtitle2"}
                sx={{
                  fontSize: "12px !important",
                  color: "#4DB8FF",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  ml: "-1px",
                  mt: "-1px"
                }}
              >
                {`NFT will be sent to your wallet, address: ${truncateEthAddress(
                  formData?.wallet_address || ""
                )}`}
              </Typography>
            </Alert>
          )}
          {selected_nft_destination_value === "wallet" &&
            isConnected &&
            !hasWalletAddress && (
              <Alert
                variant="outlined"
                severity="info"
                sx={{ color: "#4DB8FF", my: "8px" }}
              >
                <Typography
                  variant={"subtitle2"}
                  sx={{
                    fontSize: "12px !important",
                    color: "#4DB8FF",
                    fontFamily: "Open Sans",
                    fontWeight: "bold",
                    ml: "-1px",
                    mt: "-1px"
                  }}
                >
                  {`NFT will be sent to your wallet, address: ${truncateEthAddress(
                    address || ""
                  )}`}
                </Typography>
              </Alert>
            )}
          {selected_nft_destination_value === "vault" && (
            <Alert
              variant="outlined"
              severity="info"
              sx={{ color: "#4DB8FF", my: "25px" }}
            >
              <Typography
                variant={"subtitle2"}
                sx={{
                  fontSize: "14px !important",
                  color: "r#4DB8FF",
                }}
              >
                {`NFT will be sent to the vault, address: ${truncateEthAddress(
                  DAO?.nft_vault_contract.address
                )}`}
              </Typography>
            </Alert>
          )}

          <UI.Button
            type={"primary"}
            sx={{
              mt: "25px",
              color: applyCustomStyles ? "#000" : "#fff",
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#080A19",
              "&:hover": {
                backgroundColor: applyCustomStyles
                  ? `${DAO?.dao_style[0]?.data.background_color}`
                  : "#944DFF",
                border: applyCustomStyles
                  ? `1px solid ${DAO?.dao_style[0]?.data.background_color}`
                  : "1px solid #944DFF",
              },
            }}
            title={"Continue"}
          />
          {selected_nft_destination_value === "vault" && (
            <Typography
              variant={"subtitle2"}
              sx={{
                fontSize: "11px !important",
                color: "#080A19",
                fontWeight: "bold",
                lineHeight: "18px",
                mb: "45px",
                mt: "13px",
                textAlign: "center",
                fontFamily: "Open Sans",
              }}
            >
              ** By continuing, you confirm that you have read the Privacy Policy.
            </Typography>
          )}
          {selected_nft_destination_value === "wallet" && (
            <Typography
              variant={"subtitle2"}
              sx={{
                fontSize: "11px !important",
                color: "#080A19",
                fontWeight: "bold",
                lineHeight: "18px",
                mb: "45px",
                mt: "13px",
                textAlign: "center",
                fontFamily: "Open Sans",
              }}
            >
              ** By continuing, you confirm that you have read the Privacy Policy.
            </Typography>
          )}
        </form>
      </Box>
    </Box>
  );
};

const MobileHeader = ({ applyCustomStyles, DAO, previouseStep }) => {
  return (
    <Box
      onClick={previouseStep}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#080A19",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height:"20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        DESTINATION
      </Typography>
    </Box>
  );
};

export default Index;
