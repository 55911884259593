import Gateway from "../../../../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const create = async (data) => {
    const response = await gateway.post(
      `dao/${data.daoId}/collection/${data.collectionId}/free_mint/`,
      { order_id: data.orderId }
    );
    return response.data;
  };

  return {
    create,
  };
};

export default useService;
