import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useSnackbar } from "notistack";
import { useFormData } from "../../../context";

const Button = ({ DAO, onSuccess, onError, onRequestPassword, collection }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: formData } = useFormData();

  return (
    <>
      <PayPalButtons
        style={{
          layout: "horizontal",
          tagline: false,
          shape: "rect",
          color: "gold",
          height: 44,
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: collection?.total_price_with_tax,
                },
                custom_id: JSON.stringify({
                  order_id: formData?.order_id,
                  nft_destination_address: formData?.nft_destination_address,
                }),
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            enqueueSnackbar("payment completed", {
              variant: "success",
            });
            if (
              formData?.nft_destination_address ===
              DAO?.nft_vault_contract?.address
            ) {
              //user has no wallet connected
              onRequestPassword(formData?.order_id);
            } else {
              onSuccess();
            }
          });
        }}
        onCancel={() =>
          enqueueSnackbar(
            "You cancelled the payment. Try again by clicking the PayPal button",
            {
              variant: "error",
            }
          )
        }
        onError={(err) => {
          enqueueSnackbar(
            "There was an error processing your payment. If this error please contact support.",
            {
              variant: "error",
            }
          );
          onError();
        }}
      />
    </>
  );
};

export default Button;
