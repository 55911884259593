import { createTheme } from "@mui/material/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const breakpoints = createBreakpoints({});
const palette = {
  text: {
    primary: "rgba(255, 255, 255, 1)",
    secondary: "rgba(255, 255, 255, 0.8)",
  },
  color: {
    primary: "#944DFF",
    secondary: "#333645",
    darkBlue: "#0F1224",
    darkGray: "#1B1E2F",
    mediumGray: "#141728",
    lightGray: "#272A3A",
    darkRed: "#ef5350",
  },
};

const Dark = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 500,
      md: 1100,
      lg: 1400,
      xl: 1536,
      xxl: 1900,
    },
  },
  typography: {
    fontFamily: "Quantico, Open Sans",
    fontStyle: "normal",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    mainTitle: {
      fontWeight: "700",
      fontSize: "54px",
      lineHeight: "1.2",
      textTransform: "uppercase",
      [breakpoints.up("md")]: {
        fontSize: "72px",
      },
      [breakpoints.down("md")]: {
        fontSize: "54px",
      },
    },
    mainSubtitle: {
      fontSize: "20px",
      fontWeight: "400",
      fontFamily: "Open Sans",
      [breakpoints.up("md")]: {
        fontSize: "30px",
      },
      [breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    shadowTitle: {
      textTransform: "uppercase",
      color: palette.color.primary,
      opacity: "0.15",
      fontFamily: "Quantico, serif",
      fontWeight: "700",
      fontStyle: "normal",
      fontSize: "160px",
      [breakpoints.up("md")]: {
        fontSize: "160px",
        left: "50%",
        transform: "translate(-50%, -44.5%)",
      },
      [breakpoints.down("md")]: {
        fontSize: "80px",
        left: "16px",
        transform: "translate(-1.5%, -50%)",
      },
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    h1: {
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "40px",
        lineHeight: "57px",
      },
      [breakpoints.down("md")]: {
        fontSize: "35px",
        lineHeight: "40px",
      },
      fontSize: "35px",
      lineHeight: "57px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h6: {
      fontStyle: "normal",
      fontWeight: "700",
      [breakpoints.up("md")]: {
        fontSize: "30px",
        lineHeight: "57px",
      },
      [breakpoints.down("md")]: {
        fontSize: "24px",
        lineHeight: "30px",
      },
      fontSize: "35px",
      lineHeight: "57px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h2: {
      fontWeight: "700",

      [breakpoints.up("md")]: {
        fontSize: "24px",
      },
      // [breakpoints.down("md")]: {
      //   fontSize: "18px",
      // },
      fontSize: "24px",
      lineHeight: "34px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h3: {
      fontWeight: "700",
      fontSize: "22px",
      lineHeight: "34px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h4: {
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "34px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },

    h5: {
      fontFamily: "Quantico",
      fontWeight: "700",
      fontSize: "14px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h7: {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "18px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontWeight: "400",
      whiteSpace: "pre-line",
      [breakpoints.up("md")]: {
        fontSize: "18px",
      },
      [breakpoints.down("md")]: {
        fontSize: "15px",
      },
      lineHeight: "23px",
      color: palette.text.secondary,
    },
    subtitle2: {
      whiteSpace: "pre-line",
      fontFamily: "Quantico",
      fontWeight: "400",
      [breakpoints.up("md")]: {
        fontSize: "18px",
      },
      [breakpoints.down("md")]: {
        fontSize: "15px",
      },
      lineHeight: "23px",
      color: palette.text.primary,
    },
    subtitle3: {
      whiteSpace: "pre-line",
      fontFamily: "Open Sans",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "23px",
      color: palette.text.primary,
    },
    subtitle4: {
      whiteSpace: "pre-line",
      fontFamily: "Open Sans",
      fontWeight: "400",
      fontSize: "1.2rem",
      lineHeight: "23px",
      color: palette.text.secondary,
    },
  },
  palette: {
    colors: {
      primary: palette.color.primary,
      secondary: palette.color.secondary,
      darkBlue: palette.color.darkBlue,
      darkGray: palette.color.darkGray,
      lightGray: palette.color.lightGray,
      darkRed: palette.color.darkRed,
    },
    badgePrimary: {
      main: palette.color.primary,
      contrastText: "#fff",
    },
    background: {
      default: "#080A19",
    },
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
    },
    appbar: {
      text: {
        primary: palette.text.secondary,
      },
      background: palette.color.darkBlue,
    },
    buttons: {
      primary: palette.color.primary,
      secondary: palette.color.secondary,
      terciary: "transparent",
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: palette.text.secondary + "!important",
          minWidth: "unset !important",
          // "&:hover": {
          //   color: palette.text.primary + "!important",
          // },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          "&.Mui-selected": {
            color: palette.text.primary,
            borderRadius: "0px",
            backgroundColor: "#1B1E2F !important",
            border: "1px solid #944DFF",
            filter: "drop-shadow(0px 0px 15px rgba(138, 38, 255, 0.5))",
            transition: "filter 0.5s ease-in-out",
            "& .MuiListItemText-primary, & .MuiListItemIcon-root": {
              color: palette.text.primary,
            },
          },
          "&:hover": {
            border: "1px solid #944DFF",
            borderRadius: "0px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .MuiListItemButton-root": {
            height: "97px",
            justifyContent: "center",
          },
          "& .MuiListItemText-primary": {
            color: palette.text.primary,
            fontFamily: "Quantico",
            fontStyle: "normal ",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "25px",
            letterSpacing: "0.01em",
            textTransform: "uppercase",
          },
          "& .MuiListItemText-root": {
            flex: "unset",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "30px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          backgroundColor: palette.color.darkBlue,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.color.primary,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          "& .MuiTooltip-arrow": {
            color: "#575760",
          },
          // overrides the popper
          zIndex: 999999 + "!important",
        },
        tooltip: {
          backgroundColor: "#575760",
        },
      },
    },
    MuiTabIndicator: {
      styleOverrides: {
        root: {
          backgroundColor: palette.color.primary,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          [breakpoints.down("md")]: {
            height: "40px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiTab-root": {
            // [breakpoints.up("lg")]: {
            //   width: "225px",
            // },
            [breakpoints.down("md")]: {
              height: "40px",
            },
            backgroundColor: palette.color.mediumGray,
            fontFamily: "Quantico",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "43px",

            color: palette.text.primary,
            opacity: 0.4,
          },
          "&.MuiTab-root.Mui-selected": {
            backgroundColor: palette.color.darkGray,
            color: palette.text.primary,
            opacity: 1,
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          color: "#ffffff",
          backgroundColor: palette.color.darkBlue,
          width: 150,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewButton: {
          color: "#ffffff !important",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: "#0f1224",
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: "#ffffff !important",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          ".MuiAutocomplete-option": {
            "&.Mui-focused": {
              backgroundColor: palette.color.lightGray,
            },
            '&[aria-selected="true"]': {
              backgroundColor: palette.color.lightGray,
            },
            '&.Mui-focused[aria-selected="true"]': {
              backgroundColor: palette.color.lightGray,
            },
          },
        },
        endAdornment: {
          color: "white",
        },
        clearIndicator: {
          color: "white",
        },
        popupIndicator: {
          color: "white",
        },
        option: {
          padding: "0px",
          margin: "1px !important",
          backgroundColor: palette.color.darkBlue,
        },
        tag: {
          borderRadius: "0px",
          border: `1px solid  ${palette.color.lightGray}`,
          backgroundColor: palette.color.lightGray,
        },
        hasClearIcon: {
          color: "white",
        },
        inputRoot: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.color.lightGray,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.color.primary,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.color.primary,
          },
        },
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px !important",
          },

          "&.Mui-focused .MuiInputLabel-outlined": {
            color: palette.color.primary,
          },
          "&.Mui-focused .MuiOutlinedInput": {
            color: palette.color.primary,
          },
        },
        "& :hover": {
          border: `1px solid ${palette.color.primary}`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: "700",
          borderRadius: "0px",
          height: "auto !important",
          lineHeight: "1.5",
          [breakpoints.down("md")]: {
            padding: "0px 10px",
          },
          "&.MuiAlert-outlinedSuccess .MuiAlert-icon": {
            color: "rgba(77, 255, 148, 1)",
          },
          "&.MuiAlert-outlinedSuccess": {
            color: "rgba(77, 255, 148, 1)",
            borderColor:"rgba(77, 255, 148, 1)",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          background: "rgba(27, 30, 47, 0.5)",
          color: "rgba(122, 126, 146, 1)",

          "&.Mui-selected": {
            borderBottom: "2px solid #944DFF",
            background: "rgba(27, 30, 47, 1)",
            color: "rgba(255, 255, 255, 1)",
          },
        },
      },
    },
    MuiAlertMessage: {
      styleOverrides: {
        root: {
          padding: "5px",
        },
      },
    },
  },
});

export default Dark;
