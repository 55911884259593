import React from "react";
import UI from "../../../../../@components/UI";
import CustomHooks from "../../../../../@components/hooks";
import useService from "./useService";

const Button = (props) => {
  const {
    title,
    onError,
    orderId,
    DAO,
    onRequestPassword,
    collection,
    ...other
  } = props;
  const service = useService();

  const { mutate: create } = CustomHooks.usePost(service.create, {
    onSuccess: async (response) => {
      onRequestPassword({ password: response.password });
    },
  });

  return (
    <>
      <UI.Button
        onClick={() => {
          create({
            orderId,
            collectionId: collection.id,
            daoId: collection.dao_id,
          });
        }}
        sx={{
          color: "#000",
          backgroundColor: `${DAO?.dao_style[0]?.data.background_color}`,
          "&:hover": {
            backgroundColor: `${DAO?.dao_style[0]?.data.background_color}`,
            border: `1px solid ${DAO?.dao_style[0]?.data.background_color}`,
          },
        }}
        type={"primary"}
        title={title}
        {...other}
      />
    </>
  );
};

export default Button;
