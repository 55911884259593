import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import DownloadPDFButton from "./DownloadPDFButton";
import CloseIcon from "@mui/icons-material/Close";

const NFTVaultPasswordDialog = ({ DAO, collection, password, onClose }) => {
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <CloseIcon
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 20,
          top: 20,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <Typography variant={"h2"}>
        Purchase Completed - Important Information
      </Typography>
      <Box sx={{ mt: "40px" }}>
        <Typography variant={"subtitle1"}>
          {`Congratulations on your successful NFT purchase! We've securely stored
          your NFT in our vault. \n \n Here's your One-Time Password (OTP):`}
        </Typography>
        <Typography variant={"subtitle1"} sx={{ mt: "20px" }}>
          <UI.CopyToClipboard
            label={"One-Time Password"}
            value={password || ""}
            showBorder
          />
        </Typography>
        <Typography variant={"subtitle1"} sx={{ mt: "20px" }}>
          {`Please make a note of it or save it securely. This password is highly
          important for the transfer of your NFT to your wallet.\n For your convenience, we've also sent this OTP to your registered email address. If you can't find the email, please check your Spam or Junk folder. \n\nOnce you have connected your wallet with your account, you can use this OTP to authorize the transfer of the NFT from our vault to your wallet. \n\n Remember, this OTP is for single use only and crucial for your NFT's security. \n\nPlease do not share it with anyone.\n Thank you for your purchase, and enjoy your new NFT!`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <DownloadPDFButton
            password={password}
            DAO={DAO}
            collection={collection}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default NFTVaultPasswordDialog;
